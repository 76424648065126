import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { m as motion, MotionConfig, AnimationFeature } from "framer-motion"

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "logo.svg" }) {
                publicURL
            }
            site {
                siteMetadata {
                    email
                    telephone
                    mobile
                }
            }
        }
    `)

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile,
    }

    const [isOpen, setIsOpen] = useState(false)

    const Path = props => (
        <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke="#ffffff"
            {...props}
        />
    )

    return (
        <>
            <MotionConfig features={[AnimationFeature]}>
                <header className="bg-primary">
                    <div className="grid grid-cols-2 py-5 text-white container md:pb-8 lg:grid-cols-3">
                        <p className="hidden lg:flex items-center h-full">
                            <a
                                href={"tel:" + contact.tel.replace(/\s+/g, "")}
                                className="border-r border-white pr-2 leading-none"
                            >
                                {contact.tel}
                            </a>
                            <a
                                href={"tel:" + contact.mob.replace(/\s+/g, "")}
                                className="border-l border-white pl-2 leading-none"
                            >
                                {contact.mob}
                            </a>
                        </p>

                        <Link to="/" className="block">
                            <img
                                src={data.logo.publicURL}
                                alt="R. Fulcher & Sons Logo"
                                className="w-32 md:w-48 lg:mx-auto lg:w-56"
                                width="123"
                                height="48"
                            />
                        </Link>

                        <div className="flex items-center justify-end h-full">
                            <a
                                href={
                                    "mailto:" +
                                    contact.email +
                                    "?subject=Enquiry"
                                }
                                className="hidden md:block"
                            >
                                {contact.email}
                            </a>

                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="outline-none md:hidden"
                                aria-label="Menu"
                            >
                                <svg width="30" height="26" viewBox="0 0 30 26">
                                    <Path
                                        animate={isOpen ? "open" : "closed"}
                                        variants={{
                                            open: { d: "M 1 24 L 26 2.5" },
                                            closed: { d: "M 1 2.5 L 30 2.5" },
                                        }}
                                        initial="closed"
                                    />
                                    <Path
                                        animate={isOpen ? "open" : "closed"}
                                        d="M 1 13.5 L 30 13.5"
                                        variants={{
                                            open: { opacity: 0 },
                                            closed: { opacity: 1 },
                                        }}
                                        transition={{ duration: 0.1 }}
                                        initial="closed"
                                    />
                                    <Path
                                        animate={isOpen ? "open" : "closed"}
                                        variants={{
                                            open: { d: "M 1 2.5 L 26 24" },
                                            closed: { d: "M 1 24 L 30 24" },
                                        }}
                                        initial="closed"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="bg-gray-200 text-primary">
                        <div className="md:py-3 md:container">
                            <div className="relative">
                                <motion.nav
                                    animate={isOpen ? "open" : "closed"}
                                    variants={{
                                        open: { height: "auto" },
                                        closed: { height: 0 },
                                    }}
                                    initial={{ height: 0 }}
                                    transition={{ duration: 0.2 }}
                                    className="absolute top-1/2 flex justify-center overflow-hidden bg-white w-full shadow-lg z-20 md:transform md:-translate-y-1/2 md:h-auto"
                                >
                                    <ul className="flex flex-col justify-center py-3 w-full md:flex-row md:py-0">
                                        <li>
                                            <Link
                                                to="/"
                                                className="block px-8 py-3 font-bold uppercase md:text-lg"
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/about"
                                                className="block px-8 py-3 font-bold uppercase md:text-lg"
                                            >
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/services"
                                                className="block px-8 py-3 font-bold uppercase md:text-lg"
                                            >
                                                Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/galleries"
                                                className="block px-8 py-3 font-bold uppercase md:text-lg"
                                            >
                                                Galleries
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/contact"
                                                className="block px-8 py-3 font-bold uppercase md:text-lg"
                                            >
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </motion.nav>
                            </div>
                        </div>
                    </div>
                </header>
            </MotionConfig>
        </>
    )
}

export default Header
