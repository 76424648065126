import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BusinessIcon from '@material-ui/icons/Business';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';

const Ancillary = () => {
    const data = useStaticQuery(graphql`
        query {
            building: file(relativePath: {eq: "building.svg"}) {
                publicURL
            }
            site {
                siteMetadata {
                    email
                    telephone
                    mobile
                    facebook
                }
            }
        }
    `)

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile,
        facebook: data.site.siteMetadata.facebook
    };

    return (
        <>
            <aside className="bg-gray-200 max-w-none container">
                <div className="relative grid overflow-hidden md:grid-cols-2 lg:grid-cols-5 gap-y-6 py-6 md:py-10 md:gap-x-24 lg:gap-x-8">
                    <div className="relative z-10 lg:col-span-2">
                        <h3 className="mb-2 text-primary text-2xl md:mb-4 md:text-3xl xl:text-4xl">About Us</h3>
                        <p className="max-w-lg text-sm md:text-base lg:pr-20">Established in 1890, we're an award winning, family-run business offering a wide range of modern and traditional rendering solutions. Based in Leigh-on-Sea, Essex, our team of specialists have experience in a huge variety of rendering materials and will ensure that every job is finished to the highest standards.</p>
                    </div>

                    <div className="relative z-10 lg:col-start-3 lg:col-span-3">
                        <h3 className="mb-2 text-primary text-2xl md:mb-4 md:text-3xl xl:text-4xl">Contact Us</h3>
                        <ul>
                            <li className="mb-2">
                                <a href={'tel:' + contact.tel.replace(/\s+/g, '')} className="text-sm md:text-base">
                                    <BusinessIcon className="mr-3 text-primary"/>
                                    <span className="font-bold">Office</span> {contact.tel}
                                </a>
                            </li>

                            <li className="mb-2">
                                <a href={'tel:' + contact.mob.replace(/\s+/g, '')} className="text-sm md:text-base">
                                    <SmartphoneIcon className="mr-3 text-primary"/>
                                    <span className="font-bold">Mobile</span> {contact.mob}
                                </a>
                            </li>

                            <li className="mb-6">
                                <a href={'mailto:' + contact.email + '?subject=Enquiry'} className="text-sm md:text-base">
                                    <EmailIcon className="mr-3 text-primary"/>
                                    <span className="font-bold">Email</span> {contact.email}
                                </a>
                            </li>

                            <li>
                                <a href={contact.facebook} className="text-sm md:text-base">
                                    <FacebookIcon className="mr-3 text-primary"/>
                                    <span className="font-bold">Follow</span> on Facebook
                                </a>
                            </li>
                        </ul>
                    </div>

                    <img src={data.building.publicURL} alt="Building icon"
                        className="absolute bottom-0 right-0 w-1/2 max-w-sm sm:w-1/4"/>
                </div>
            </aside>
        </>
    )
};

export default Ancillary;
