import React from "react";
import Header from "./header";
import Ancillary from "./ancillary";
import Footer from "./footer";

const Layout = ({ children }) => {
    return (
        <>
            <div className="flex flex-col w-full h-screen">
                <Header/>
                <main className="flex-grow">{children}</main>
                <Ancillary/>
                <Footer/>
            </div>
        </>
    )
};

export default Layout;
