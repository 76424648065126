import React from "react";
import { Link } from "gatsby";

const Footer = () => {

    return (
        <>
            <footer className="bg-white py-8 z-20 max-w-none container">
                <div className="flex flex-col px-2 md:flex-row md:items-center md:justify-between">
                    <p className="mb-4 leading-tight text-center text-gray-700 md:mb-0 md:text-left lg:leading-none">
                        <small className="text-xs lg:text-sm">Copyright © {new Date().getFullYear()} R. Fulcher & Sons. All rights reserved. Site design by <a href="https://www.enovate.co.uk/" target="_blank" rel="noreferrer">Enovate</a>.</small>
                    </p>

                    <ul className="flex justify-center text-xs lg:justify-end lg:text-sm">
                        <li className="mr-4 leading-none">
                            <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li className="mr-4 leading-none">
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                        <li className="leading-none">
                            <Link to="/sitemap">Sitemap</Link>
                        </li>
                    </ul>
                </div>
            </footer>

        </>
    )
};

export default Footer;
